/*********************************************************** commun ***********************************************************/
//fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import 'react-big-calendar/lib/sass/styles';
//colors
$primary: #c10058;
$secondary: #50E3C2;
$colorTxt1: #1F3149;
$colorTxt2: #9AA6B7;
$colorBackground: #EFF2FF;
$colorGreenBack: #00E5AC;
$colorGreenBorder: #28C49D;
$alertButton: #CC1A1A;

$quarterHeight: 15px;

#root{
  padding: 50px 50px 50px 0;
  zoom: 80%;
}
body{
  font-family: Montserrat !important;
}
.w100 {
  font-weight: 100;
}
.w200 {
  font-weight: 200;
}
.w300 {
  font-weight: 300;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.wBold {
  font-weight: bold;
}
.font-quicksand-class{
  font-family: Quicksand;
}
//spacer
.spacer{
  padding: 20px;
}
.spacer-10{
  padding: 10px;
}
label{
  font-size: 14px;
}
.center-text{
  text-align: center !important;
}
//link
a {
  text-decoration: none !important;
  color: $colorTxt1 !important;
}
//alerts badges
.bg-alert-danger{
  background-color: $alertButton;
  color:  white;
}
.alert-with-radius{
  text-align: center;
  background-color: $alertButton;
  color:  white;
  border-radius: 20px;
  padding: 20px;
}
.bg-success-custom{
  background-color: $colorGreenBack !important;
  border-color: $colorGreenBack !important;
  cursor: pointer;
}
.bg-success-custom:hover{
  border-color: $colorGreenBack !important;
  opacity: 0.8 !important;
}
.bg-danger{
  background-color: $alertButton !important;
  cursor: pointer;
}
.bg-warn{
  color: #fff !important;
  cursor: pointer;
}
.table-badge{
  border-radius: 25px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 10px !important;
  width: 10px !important;  
  padding: 2px !important;
}  
.prestations-u-badge{
  border-radius: 5px !important;
  margin-right: 4px !important;
  padding: 2px 10px !important;
}  
.confirmation-alert{
  font-size: 13px !important;
}
//errors
.error-message{
  color: $alertButton;
}

//titles
.main-primary-title{
  font-family: Montserrat !important;
  text-transform: lowercase;
  font-size: 50px;
  font-weight: bold;
}
.main-primary-title::first-letter{
  text-transform: capitalize !important;
}
.main-secondary-title{
  font-size: 22px;
  font-weight: bold;
  color: $colorTxt2;
  text-transform: lowercase;
}
.main-secondary-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-main-title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  text-transform: lowercase;
  padding-left: 20px !important;
}
.blocs-main-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-secondary-title{
  font-size: 15px;
  font-weight: bold;
  color: $colorTxt2;
  margin-bottom: 0px;
  padding-left: 20px !important;
}
.small-title{
  font-family: Quicksand !important;
  font-size: 18px;
  font-weight: bold;
  color: $colorTxt2;
  letter-spacing: 2px;
}
.small-title::first-letter{
  text-transform: capitalize !important;
}
.small-seconde-title{
  font-family: Quicksand !important;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: $colorTxt2;
}
.small-seconde-title::first-letter{
  text-transform: capitalize !important;
}
.paragraph{
  text-align: justify;
  font-size: 14px;
  color: $colorTxt2;
}
//scroll main hidden
.main::-webkit-scrollbar { width: 0 ! important }
.main { overflow: -moz-scrollbars-none; }
.main { -ms-overflow-style: none; }
//app body
.app-body {
  color: $colorTxt1;
  display: flex;
}
//green bar
.green-bar{
  width: 100px;
  background-color: $colorGreenBack;
  height: 2px;
}
.inputs{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  height: 45px !important;
}
.inputs:focus {
  outline: 0px !important;
}
button:focus {
  outline: 0px !important;
}
.inputs-only-show{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 80% !important;
}
.inputs-only-show:focus {
  outline: 0px !important;
}

.inputs-txt-area{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  overflow: hidden;
}
.custom-file-label {
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  height: 45px !important;
  overflow-wrap: break-word !important;
  text-overflow: ellipsis !important;
}
.custom-file-label::after {
  display: none !important;
 }
.icon-file-input{
  padding-top: 6px;
  padding-left: 20px;
}
.icon-file-input:hover{
  cursor: pointer;
  opacity: 0.4;
}

//currsor pointer
.curssorPointer{
  cursor: pointer;
}
.curssorPointer:hover{
  transform: scale(1.01)
}
.active{
  color: #C10058
}
.redirect-links-bloc{
  font-size: 14px;
}
.error-message{
  font-size: 13px;
}
.crediter-temps-span{
  font-size: 10px;
  b{
    padding: 2px;  
  }
}
.sub-item-nav{
margin-left: 100px !important;
}
.space-between{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.stats-phrase{
  font-family: Quicksand !important;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: $primary;
}
.iframe_stats{
  position: relative;
  display: initial;
  width: 100vh !important;
  height: 100vh !important;
  border: 0px;
}
.schedule{
  justify-content: center;
  label {
    font-size: 11px !important;
    padding-top: 10px;
    font-weight: bold;
  }
}
.schedule-border{
//  border: solid 1px $colorTxt2;
}
.schedule-switch{
  margin-top: 10px;
  margin-left: 5px;
  label{
    padding-top: 3px;
  }
}
.planification-line{
  display: flex !important;
  justify-content: space-between !important;
}
.drop-down-header{
  cursor: pointer;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.drop-down-header-div{
  justify-content: left;
  height: 55px;
  border: $colorTxt2 solid 1px;
  border-radius: 25px;
  background-color: white !important;
  padding:  2px 20px;
}
.drop-down-header-link{
  padding: 15px 10px;
  margin : 0px !important;
  justify-content: left;
}
.avatar{
  vertical-align: middle;
  width: 50px !important;
  height: 50px !important;
  border: solid 1px $colorTxt2;
}
.drop-down-header-input{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  height: 50px !important;
}
.drop-down-header-input:focus {
  outline: 0px !important;
}
.search-input{
  padding: 2px !important;
  margin-bottom: 10px !important;
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
  width: 100% !important;
  height: 45px !important;
  background-color: #ffffff;
  .css-15e6m3o-control{
    border-radius: 23px !important;
  }
  .css-yk16xz-control{
    border: 0px !important;
  }
  .css-g1d714-ValueContainer:focus{
    outline: 0px !important;
  }
 div{
   border-radius: 13px !important;
 }
}
.search-input:focus {
  outline: 0px !important;
}

/*********************************************************** invoice **********************************************************/
.prices-bottom-bloc-row{
  padding: 30px;
}
.prices-bottom-bloc{
  display: flex;
  padding-bottom: 10px ;
  justify-content: space-between;  
  border-bottom: 1px solid $colorBackground;
}
/*********************************************************** modal ************************************************************/
.modal-lg-custom{
  max-width: 70% !important;
}
.nested-modal-lg-custom{
  max-width: 67% !important;
  padding: 0px !important;
//  margin-top: 5% !important;
}
.modal-header-bloc{
  padding: 20px 40px 10px 40px !important;
  justify-content: space-between;
}
/*********************************************************** agenda ************************************************************/
.quarter{
  height: $quarterHeight;
  width: 100%;
  box-sizing: border-box;
}
.rbc-today{
  background-color: #fff;
}

/*********************************************************** tables ************************************************************/
.table-bloc{
  font-size: 13px;
  background-color: #fff;
  border-radius: 20px;
  text-align: left;
  td{
    vertical-align: middle !important;
    padding: 8px 18px !important;
    height: 50px !important;
  }
  th{
    border: 0px !important;
    color: $colorTxt2;
    padding-left: 20px !important;
  }
  thead th input{
    font-size: 13px !important;
  }
}
.custom-first-elemnt thead tr th:first-child, tbody tr td:first-child {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  word-break: break-all;
}
.search-input-table{
  height: 38px !important;
}
/*********************************************************** tabs **************************************************************/
.flow-up-tabs{
  background-color: $colorBackground;
  li{
    width: 33.33% !important;
    text-align: center;
  }
}
.flow-up-tabs-content{
  padding: 40px 0 40px 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color:$colorGreenBorder !important;
  background-color: $colorGreenBack !important;
}
.tabs-bloc{
  border: 1px $colorTxt2 solid;
  padding: 20px;
}
.show-plus{
  cursor: pointer;
  padding: 20px;
  margin: 10px;
}
.capitalize-word {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
}

/*********************************************************** buttons ***********************************************************/
.green-button{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  padding: 5px 70px 5px 70px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  height: 45px !important;
}
.login-button{
  text-align: center;
  color: #fff;
  background-color: #C10058;
  border: 3px solid #C10058;
  border-radius: 30px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}
.table-button{
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin: 0px !important;
  font-size: 13px !important;
}
.add-button{
  padding: 10px;
  text-align: center !important;
  color: #fff !important;
  background-color: $colorGreenBack !important;
  border: 3px solid $colorGreenBorder !important;
  border-radius: 30px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin: 0px !important;
  font-size: 13px !important;
}
.add-button-no-cursor{
  padding: 2px !important;
  cursor: default !important;
  font-size: 10px !important;
}
.input-table{
  color: $colorTxt2 !important;
  border-radius: 23px !important;
  border: 1px solid $colorTxt2;
}

/*********************************************************** login page ******************************************************/
.login-page{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}
.login-form{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.login-form div{
  padding: 20px 0px 20px 0px;
}
.login-preloader{
  display: flex !important;
  justify-content: center !important;
}
.login-preloader div {
  padding: 0px !important;
}
.backgroud-image-home{
  background-image: url("./assets/bg/partenaire-scaled.jpg");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.login-passe-forget:hover{
  cursor: pointer;
  text-decoration: underline;
}
.bottom-fixed{
  margin: 0;
  position: absolute;
  bottom: 2%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.radio-choices{
  p{
    padding: 15px 30px;
    input{
      margin-right: 5px;
    }
  }
}
/*********************************************************** blocs ***********************************************************/
.bloc-commun-style{
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  margin: 20px 0;
  align-items: center;
  opacity: 1;
  word-break: break-word;
}
.bloc-commun-style:hover{
  transform: scale(1.01)
}
.blocs-results{
  text-align: center;
}
.blocs-results span{
  font-size: 40px;
  font-weight: bold;
}
/**** header ****/
.navbar-light{
  background-color: $colorBackground !important;
  height: 160px;
  border-radius: 40px 40px 0 0 ;
}
.navbar{
  padding: 0px !important;
}
.app-header{
  height: 160px;
  right: 50px;
  .navbar-toggler{
    display: none;
  }
  .navbar-brand {
    width: 20%;
    height: 100%;
    background-color: #fff;
  }
  .navbar-brand img{
    width: 170px;
  }
  .navbar-nav{
    height: 130px;
    width: 80%;
    padding: 0 30px;
    margin-top: 30px;
    border-radius: 40px 40px 0px 0;
    justify-content: space-between;
    background-color: $colorBackground;
  }
  .md-avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border: solid 1px;
  }
}
/*********************************************************** sidebar ***********************************************************/
.sidebar {
  width: 20%;
  text-align: center;
  .navbar-nav{
    width: 100%;    
    padding-top: 40px;
  }
  .nav-item {
    padding: 5px 5px 5px 20px;
    width: 100%;
    display: flex;
    letter-spacing: 0px;

    font-size: 16px;
    font-weight: bold;
    a{
      color: #9AA6B7 !important;      
    }
    span{
      color: #9AA6B7 !important;      
      margin-left: 30px !important;
      margin-right: 0px !important;
    }
  }

  .nav-item:hover {
    background-color:#EFF2FF ;
    a{
      color: $colorTxt1;
    }
    span{
      color: $colorTxt1;
    }
  }

  .nav-item.active {
    color: $primary;
    background-color: $colorBackground;
    a{
      color: $colorTxt1;
    }
  }
  .nav-item.active:hover {
    color: $secondary;
    background-color: #fff;
  }
  .sidebar-footer{
    background-color: #fff;
  }
  .sidebar-minimizer {
    display: none;
  }
}
/*********************************************************** main ***********************************************************/
.main{
  background-color: $colorBackground;
  overflow: auto;
  border-radius: 40px 40px 40px 40px;
  width: 100%;
  min-height: 90vh;
  padding: 60px;
  .main-footer{
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 1030;
    background-color: #fff;
  }
}
/*********************************************************** links and others ***********************************************************/
.links{
  cursor: pointer;
  text-decoration: underline !important;
  padding: 0px 20px 0px 20px;
  font-size: 13px;
  color: $colorTxt2 !important;
}
.links:hover{
  text-decoration: none !important;
}
.img-redirect-links{
  margin: 20px 20px 20px 0px;
  width: 100%;
  height: auto;
  min-height: 300px;
  border-radius: 20px !important;
}
.headerMobile{
  display: none;
}
/**********************************************************************************************************************************/
/*********************************************************** responsive ***********************************************************/
/**********************************************************************************************************************************/
@media (max-width: 1440px) {
  .sidebar{
    width: 25% !important;
  }
  .main{
    width: 75%;
  }
  .app-header .navbar-nav {
    width: 75%;
  }
  .app-header .navbar-brand {
    width: 25%;
  }
}
@media (max-width: 800px) {
  #root{
    padding: 30px 0px 50px 0px;
  }
  .padding-x-responsive{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .padding-top-responsive{
    padding-top: 20px;
  }
  // commun 
  .app-body {
    margin-top: 10px !important;
  }
  .mainContent{
    max-width: 100%;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .app-header{
    height: 70px;
    right: 0px;
    border: 0;
  }
  .headerMobile{
    display: flex;
  }
  .app-header .navbar-brand img {
    width: 110px; 
  }
  .app-header .navbar-toggler {
    display: block;
  }
  .app-header .navbar-nav {
    background-color: transparent;
    height: 70px;
    padding: 0;
    margin: 0px;
    border-radius: 0px;
  }
  html:not([dir="rtl"]) .sidebar {
    margin-left: -100%;
  }
  .app-header .navbar-brand {
    width: 155px;
    height: 100%;
    background-color: transparent;
  }
  .sidebar {
    width: 100% !important;
    height: 100% !important;
    left: 0;
    padding-left: 0px;
    display: none;
  }
    .main{
      margin: 0px 0px 0px 0px !important;
      padding: 0px 0px 0px 0px !important;
      position: initial;
      border-radius: 0;
      width: 100%;
      .main-footer{
        height: 0px;
        width: 100%;
        position: fixed;
        bottom: 0px;
        z-index: 1030;
        background-color: #fff;
      }
    }
  .d-flex{
    display: block !important;
  }
  .green-button {
    padding: 5px 40px 5px 40px;
  }
  .prix{
    font-size: 50px !important;
  }
  .main-primary-title{
    font-size: 22px;
  }
  .main-secondary-title{
    padding-top: 20px;
    font-size: 17px !important;
  }
  .blocs-results{
    span{
      font-size: 30px;
    }
  }
  .blocs-main-title{
    padding-left: 0px !important;
  }
  .blocs-secondary-title{
    padding-left: 0px !important;
  }
  .icons-and-infos{
    justify-content: space-between;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .icons-and-infos span{
    padding-left: 20px;
  }
  .permis-title{
    font-size: 16px;
    font-weight: bold;
  }
  .agencies-list{
    p{
      font-size: 10px;
    }
  }
  .reverse-responsive{
    flex-direction: column-reverse;
  }
  .padding-responsive{
    padding-left: 15px;
    padding-right: 15px;
  }
}
.backSelect{
  background-color: #fff;
}
